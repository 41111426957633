import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import PortalLogoLight from "../../../src/assets/images/PortalLogoLight.svg"
import PortalLogo from "../../../src/assets/images/PortalLogo.svg"
import configPortalLogo from "../../../src/assets/images/ConfigPortalLogo.svg"
import {
  Grid,
  Dialog,
  IconButton,
  Button,
  MenuItem,
  Select,
  Avatar,
  Menu,
  Tooltip
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { NavLink, Prompt, useLocation } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import { PATH } from "../../../src/constants/paths"
import SelectCustomer from "../../../src/pages/SelectCustomer/SelectCustomer"
import {
  setCompanyDetails,
  setCustomerForOperation,
  setCustomerNameForOperation,
  setCustomers
} from "../../../src/redux/slices/settingsSlice"
import multiCustomerLogo from "../../../src/assets/images/MultiCustomer.png"
import {
  getConfigurationDecryptAndSetRedux,
  getPermissionsForUser,
  getResCDsConfigDecryption,
  getTranslations,
  prepareMsalConfig,
  useQuery
} from "../../../src/utils/helper"
import { RootState, useAppThunkDispatch } from "../../../src/redux/store"
import {
  FEATURES,
  HEADER_CONSTANTS,
  KEY_CODES,
  PERMISSIONS,
  View
} from "../../../src/utils/constants"
import { getUserAccounts } from "../../../src/redux/slices/usersSlice"
import { msalConfig } from "../../../src/config/authConfig"
import { PublicClientApplication } from "@azure/msal-browser"
import { availableLanguages, Translates } from "../../../src/i18n/i18n"
import { useTranslation } from "react-i18next"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  setIsRedirectHome,
  setModalOpen
} from "src/redux/slices/ConfigPortal/CustomerSelectionSlice"
import {
  setConfigFilesByCustomersRedux,
  setSelectedCustomerIds
} from "src/redux/slices/MultiEventOutputSlice"
import { ConfigService } from "src/service/config.service"
import { setCustomerConfiguration } from "src/redux/slices/configSlice"
import { ConfigTabNames } from "src/configurationPortal/pages/helper"
const Header = (_props: any) => {
  const { t, i18n } = useTranslation<any>()
  const dispatch = useAppThunkDispatch()
  let location = useLocation() as any
  const configService = new ConfigService()

  let queryParams: any = useQuery()
  const [toggleMenu, setToggleMenu] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isEditConfig, setIsEditConfig] = useState(null)
  const [customersList, setCustomersList] = useState([])
  const [selectedCustomers, setSelectedCustomers] = useState([] as any)
  const [path, setPath] = useState("")
  const userEmail = localStorage.getItem("username") || ""
  const [isMultiCustomer, setIsMultiCustomer] = useState(true)
  const CONFIG_PATH = {
    generalSettings: { path: PATH.CONFIGURATION_PORTAL },
    categories: { path: PATH.CATEGORIES },
    countFields: { path: PATH.CONFIGURATION_COUNT_FIELDS },
    lookupRoutines: { path: PATH.CONFIG_LOOKUP_ROUTINES },
    reportings: { path: PATH.REPORTS },
    variances: { path: PATH.VARIANCES },
    outputFiles: { path: PATH.OUTPUT_FILES },
    audits: { path: PATH.AUDITS },
    countPrograms: { path: PATH.COUNT_PROGRAMS },
    inputFiles: { path: PATH.INPUT_FILES }
  }
  const [anchorEl, setAnchorEl] = React.useState(null)
  const userMenuOpen = Boolean(anchorEl)

  const [configPath, setConfigPath] = useState(CONFIG_PATH)
  const configSliceData = useSelector((state: RootState) => state.config)
  const authSliceData = useSelector((state: RootState) => state.auth)
  const configTypeFromStore = configSliceData?.configType
  const configDataFromStore = configSliceData?.configDetails
  const warnOnTabChange = configSliceData?.warnOnTabChange
  const configNameFromStore = configSliceData?.configName
  const userAccounts = useSelector(
    (state: RootState) => state.user?.userAccounts
  )
  const companyDetails = useSelector(
    (state: RootState) => state.settings?.companyDetails
  )
  const customerSelectionModalOpen = useSelector(
    (state: RootState) => state.customerSelection?.modalOpen
  )

  const selectedCustomerIds = useSelector(
    (state: RootState) => state.multiEventOutput?.selectedCustomerIds
  )
  const [configName, setConfigName] = useState("")
  const [userPermissions, setUserPermissions] = useState([] as any)
  const [version, setVersion] = useState("")

  useEffect(() => {
    let isMounted = true
    //istanbul ignore next
    if (
      isMounted &&
      configSliceData?.customerConfigurationsStatus === "success" &&
      authSliceData?.keys?.length &&
      !configSliceData?.isGetConfigsDecrypt
    ) {
      const data1 = getResCDsConfigDecryption(
        configSliceData.customerConfigurations
      )
      dispatch(setCustomerConfiguration(data1))
    }
    return () => {
      isMounted = false
    }
  }, [
    configSliceData?.customerConfigurationsStatus,
    authSliceData?.keys,
    configSliceData?.isGetConfigsDecrypt
  ])

  useEffect(() => {
    const dt = getPermissionsForUser()
    setUserPermissions(
      (dt || []).map((m: { Permissions: any[] }) => {
        return { ...m, Permissions: m.Permissions.map(e => e.toLowerCase()) }
      })
    )
  }, [])
  const checkDisabledStatus = (param: any) => {
    if (param === "") {
      return false
    }
    const _res = userPermissions.find(
      (f: { Feature: any }) => f.Feature.toLowerCase() === param
    )
    if (_res && _res.Permissions?.includes(PERMISSIONS.VIEW)) {
      return false
    } else {
      return true
    }
  }
  const prepareEndPoint = pathString => {
    //istanbul ignore next
    if (location.search?.length) {
      return `${pathString}${location.search}`
    }
    return pathString
  }
  const setConfigPaths = () => {
    setConfigPath({
      generalSettings: { path: prepareEndPoint(PATH.CONFIGURATION_PORTAL) },
      categories: { path: prepareEndPoint(PATH.CATEGORIES) },
      countFields: { path: prepareEndPoint(PATH.CONFIGURATION_COUNT_FIELDS) },
      lookupRoutines: { path: prepareEndPoint(PATH.CONFIG_LOOKUP_ROUTINES) },
      reportings: { path: prepareEndPoint(PATH.REPORTS) },
      variances: { path: prepareEndPoint(PATH.VARIANCES) },
      outputFiles: { path: prepareEndPoint(PATH.OUTPUT_FILES) },
      audits: { path: prepareEndPoint(PATH.AUDITS) },
      countPrograms: { path: prepareEndPoint(PATH.COUNT_PROGRAMS) },
      inputFiles: { path: prepareEndPoint(PATH.INPUT_FILES) }
    })
  }
  const fullName = localStorage.getItem("name") ?? ""
  const [firstName, lastName] = fullName.split(" ")
  const firstInitial = firstName ? firstName.charAt(0) : ""
  const lastInitial = lastName ? lastName.charAt(0) : ""

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setIsMultiCustomer(selectedCustomers.length > 1)
    }
    return () => {
      isMounted = false
    }
  }, [selectedCustomers])

  useEffect(() => {
    dispatch(setCompanyDetails(companyDetails))
  }, [companyDetails])

  const getCustomerList = () => {
    if (!userAccounts?.length) {
      setLoading(true)
      dispatch(getUserAccounts(userEmail))
        .then((temp: any) => {
          setUserDetails(temp.payload?.data)
        })
        .catch(
          //istanbul ignore next
          _err => {
            setLoading(false)
            _props.handleLoadChild()
          }
        )
    } else {
      setUserDetails(userAccounts)
    }
  }
  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
  }, [])
  //istanbul ignore next
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const setUserDetails = data => {
    let res = data || []
    res = res.map((c: any) => {
      return {
        name: c.companyName,
        img: companyDetails?.companyLogo || c.companyLogo,
        selected: c.isCustomerSelected,
        idCustomer: c.idCustomer,
        ...c
      }
    })
    let selected = res.filter(c => c.isCustomerSelected)
    setSelectedCustomers(selected)
    if (selected.length == 1) {
      dispatch(setCustomerForOperation(selected[0].idCustomer))
      dispatch(setCustomerNameForOperation(selected[0].name))
    }
    setCustomersList(res)
    dispatch(setCustomers(res))
    setLoading(false)
    setTimeout(() => {
      _props.handleLoadChild()
    })
  }

  useEffect(() => {
    let isMounted = true
    const pathname = window.location.pathname
    if (isMounted) {
      const selectedUser = userAccounts
        ?.filter(c => c.isCustomerSelected)
        ?.map(i => i.idCustomer)
      if (selectedUser?.length) {
        if (
          JSON.stringify(selectedCustomerIds) !==
            JSON.stringify(selectedUser) &&
          pathname.includes(
            ConfigTabNames.MultiEventOutputs.toLocaleLowerCase()
          )
        ) {
          configService.getConfigDetails(selectedUser).then(
            //istanbul ignore next
            (temp: any) => {
              if (Array.isArray(temp.data)) {
                const data = temp.data
                  .filter(i => i.status === "Active")
                  .map(i => ({
                    configId: i.idConfig,
                    customerId: i.idCustomer,
                    configName: i.name,
                    configVersion: i.version
                  }))
                dispatch(setConfigFilesByCustomersRedux(data))
                dispatch(setSelectedCustomerIds(selectedUser))
              }
            }
          )
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [userAccounts])
  useEffect(() => {
    if (configDataFromStore?.configuration && authSliceData?.keys?.length) {
      const configObject = getConfigurationDecryptAndSetRedux(configSliceData)
      setConfigName(configObject.GeneralSettings?.ConfigurationName)
      setVersion(configObject.GeneralSettings?.ConfigurationVersion)
    }
  }, [configDataFromStore, authSliceData?.keys])

  useEffect(() => {
    let pathSplit = location?.pathname?.split("/")
    if (pathSplit?.length > 2) {
      setPath(pathSplit[1])
    }
    setIsEditConfig(queryParams?.get("edit"))
    setConfigPaths()
  }, [])
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getCustomerList()
    }
    return () => {
      isMounted = false
    }
  }, [userAccounts])

  useEffect(() => {
    setConfigName(configNameFromStore)
  }, [configNameFromStore])

  //istanbul ignore next
  const clickToggleMenu = () => {
    let toggledOption = !toggleMenu
    setToggleMenu(toggledOption)
  }

  //istanbul ignore next
  const handleClickToggleMenuKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      clickToggleMenu()
    }
  }, [])
  const [open, setOpen] = useState(false)

  //istanbul ignore next
  const handleClickOpen = () => {
    if (customersList.length === 1) return
    dispatch(setModalOpen(true))
    dispatch(setIsRedirectHome(true))
  }

  //istanbul ignore next
  const handleClickOpenKeyUp = useCallback((e: any) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleClickOpen()
    }
  }, [])

  //istanbul ignore next
  const close = () => {
    dispatch(setModalOpen(false))
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setOpen(customerSelectionModalOpen)
      if (!customerSelectionModalOpen) {
        dispatch(setIsRedirectHome(customerSelectionModalOpen))
      }
    }
    return () => {
      isMounted = false
    }
    //eslint-disable-next-line
  }, [customerSelectionModalOpen])

  const handleLogout = () => {
    let mConfig: any = prepareMsalConfig(
      { domain_hint: "", login_hint: "" },
      msalConfig,
      true
    )
    const instance = new PublicClientApplication(mConfig)
    // localStorage.clear()
    // instance.logoutRedirect()
    //TO be removed later once tested
    instance
      .logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/" // redirects the top level app after logout
      })
      .then(() => {
        localStorage.clear()
        i18n.changeLanguage("English")
      })
  }
  const renderLogo = () => {
    if (
      selectedCustomers &&
      selectedCustomers.length === 1 &&
      selectedCustomers[0].companyLogo
    ) {
      return <img src={selectedCustomers[0].companyLogo} alt="customer logo" />
    }
    return <img src={multiCustomerLogo} alt="customer logo" />
  }
  const languageMap = {
    English: "English (USA)",
    French: "Français (Canadian)",
    Spanish: "Spanish (Mexico)"
  }
  const renderName = () => {
    if (selectedCustomers && selectedCustomers.length == 1) {
      return (
        <span>
          {selectedCustomers[0].companyName}{" "}
          {path === PATH.CONFIGURATION.replace("/", "") &&
          isEditConfig != "false"
            ? "- " +
              (configDataFromStore.name || configName) +
              " V" +
              (configDataFromStore.version || version)
            : null}
        </span>
      )
    }
    return <span>{`${selectedCustomers.length} Selected`}</span>
  }
  const checkDisableLink = () => {
    return selectedCustomers.length ? isMultiCustomer : true
  }

  //istanbul ignore next
  const isConfigViewMode = () => {
    const MIN_ROUTE_LEN = 2
    const PATHS = location.pathname.split("/")
    if (
      PATHS.length > MIN_ROUTE_LEN &&
      location.pathname.includes(PATH.CONFIGURATION)
    ) {
      return configTypeFromStore === View
    } else {
      return false
    }
  }
  return (
    <div
      area-label={i18n.language}
      className={`${
        path === PATH.CONFIGURATION.replace("/", "") ? "config-portal" : ""
      } ${configTypeFromStore === "view" ? "config-portal__view" : ""}`}
    >
      <header className={`header`}>
        {" "}
        <div className="main-container">
          <Grid container>
            <Grid item xs={5} className="header__logo">
              {path !== PATH.CONFIGURATION.replace("/", "") ? (
                <IconButton
                  className="header__logo--btn"
                  onClick={clickToggleMenu}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              {path !== PATH.CONFIGURATION.replace("/", "") ? (
                <img
                  src={PortalLogoLight}
                  alt={"Portal Logo"}
                  className="mt-1"
                  data-testid="portal-logo"
                />
              ) : (
                <img src={configPortalLogo} alt={"Config Portal Logo"} />
              )}
            </Grid>
            <Grid item xs={7} className="header__actions">
              <div>
                <span className="header__actions--btn">
                  <Select
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                      },

                      transformOrigin: {
                        vertical: "top",
                        horizontal: "right"
                      }
                    }}
                    onChange={e => i18n.changeLanguage(e.target.value)}
                    labelId="language-dropdown"
                    id="language-dropdown"
                    className="lang-dropdown"
                    value={i18n.language}
                    defaultValue={i18n.language}
                  >
                    {availableLanguages.map(language => (
                      <MenuItem
                        className="dropdown-list lang-dropdown__list"
                        value={language}
                        key={language}
                      >
                        <small className={language}>
                          {languageMap[language]}
                        </small>
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              </div>
              <div
                className={
                  customersList.length > 1
                    ? "header__actions--logo pointer"
                    : "header__actions--logo"
                }
                onClick={handleClickOpen}
                onKeyUp={handleClickOpenKeyUp}
              >
                {!loading && renderLogo()}
                {!loading && renderName()}
                {loading && (
                  <span>{getTranslations(t, Translates.Fetching)}</span>
                )}
              </div>
              <div className="header__actions--profile">
                <Tooltip arrow title="Account">
                  <IconButton
                    id="basic-button"
                    aria-controls={userMenuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={userMenuOpen ? "true" : undefined}
                    onClick={handleClick}
                    data-testid="some-anchor-element"
                  >
                    <Avatar>
                      {firstInitial.toUpperCase()}
                      {lastInitial && `${lastInitial.toUpperCase()}`}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  className="profile-dropdown"
                  anchorEl={anchorEl}
                  open={userMenuOpen}
                  onClose={handleClose}
                  data-testid="some-close-button"
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <li className="profile-dropdown__name">
                    {localStorage.getItem("name")}
                  </li>
                  <li className="profile-dropdown__email">{userEmail}</li>
                  <li className="profile-dropdown__logout">
                    <Button
                      variant="contained"
                      className="primary-btn"
                      onClick={handleLogout}
                      data-testid="logout"
                    >
                      {getTranslations(t, Translates.Logout)}
                    </Button>
                  </li>
                </Menu>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={`left-nav ${!toggleMenu ? "active" : ""}`}>
          <div className="left-nav__header">
            <IconButton className="left-nav__close" onClick={clickToggleMenu}>
              <CloseIcon />
            </IconButton>
            <img src={PortalLogo} alt={"Portal Logo"} className="mt-1" />
          </div>
          <ul>
            <li>
              <NavLink to={PATH.HOME}>
                {getTranslations(t, Translates.Home)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.SCHEDULE}
                className={
                  !selectedCustomers.length ||
                  checkDisabledStatus(FEATURES.SCHEDULE)
                    ? "link-disabled"
                    : ""
                }
              >
                {getTranslations(t, Translates.Schedule)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.DEVICE_RESERVATION}
                className={
                  !selectedCustomers.length ||
                  checkDisabledStatus(FEATURES.RESERVATIONS)
                    ? "link-disabled"
                    : ""
                }
              >
                {getTranslations(t, Translates.Device_Reservation)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.LOCATIONS}
                className={
                  checkDisableLink() || checkDisabledStatus(FEATURES.LOCATIONS)
                    ? "link-disabled"
                    : ""
                }
              >
                {getTranslations(t, Translates.Locations)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.UPLOADFILES}
                className={
                  checkDisableLink() ||
                  checkDisabledStatus(FEATURES.UPLOADFILES)
                    ? "link-disabled"
                    : ""
                }
              >
                {getTranslations(t, Translates.Upload_Files)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.CONFIGURATION}
                className={
                  checkDisableLink() ||
                  checkDisabledStatus(FEATURES.CONFIGURATION)
                    ? "link-disabled"
                    : ""
                }
              >
                {getTranslations(t, Translates.Configuration)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.LIVE_EVENTS}
                className={checkDisableLink() ? "link-disabled" : ""}
              >
                {getTranslations(t, Translates.Live_Events)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.FINAL_REPORTS_AND_FILES}
                className={checkDisableLink() ? "link-disabled" : ""}
              >
                {getTranslations(t, Translates.Final_Reports_Files)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH.SETTINGS}
                className={checkDisableLink() ? "link-disabled" : ""}
              >
                {getTranslations(t, Translates.Settings)}
              </NavLink>
            </li>
          </ul>
          {/* <span className="left-nav__footer">
            {getTranslations(t, Translates.WIS_Contacts)}
          </span> */}
        </div>
        <div
          className="left-nav__overlay"
          onClick={clickToggleMenu}
          onKeyUp={handleClickToggleMenuKeyUp}
        ></div>
        <Dialog fullScreen open={open}>
          <SelectCustomer close={close} />
        </Dialog>
      </header>
      {path === PATH.CONFIGURATION.replace("/", "") ? (
        <Grid container className="config-nav">
          <Grid item xs={12}>
            <div className="main-container">
              <NavLink
                to={configPath.generalSettings.path}
                activeClassName="active"
              >
                {getTranslations(t, Translates.General_Settings)}
              </NavLink>
              <NavLink
                to={configPath.inputFiles.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.InputFile)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Input_Files)}
              </NavLink>
              <NavLink
                to={configPath.categories.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Category)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Categories)}
              </NavLink>
              <NavLink
                to={configPath.countFields.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.CountField) ||
                  location.pathname.includes(HEADER_CONSTANTS.count_fields)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Count_Fields)}
              </NavLink>
              <NavLink
                to={configPath.lookupRoutines.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Lookup)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Lookup_Routines)}
              </NavLink>
              <NavLink to={configPath.countPrograms.path}>
                {getTranslations(t, Translates.Count_Programs)}
              </NavLink>
              <NavLink
                to={configPath.variances.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Variance)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Variances)}
              </NavLink>
              <NavLink
                to={configPath.audits.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Audit)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Audits)}
              </NavLink>
              <NavLink
                to={configPath.reportings.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Report)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Reports)}
              </NavLink>
              <NavLink
                to={configPath.outputFiles.path}
                className={
                  location.pathname.includes(HEADER_CONSTANTS.Output)
                    ? "active"
                    : ""
                }
              >
                {getTranslations(t, Translates.Outputs)}
              </NavLink>
            </div>
            {configTypeFromStore === "view" ? (
              ""
            ) : (
              <Prompt
                when={warnOnTabChange}
                message={`${getTranslations(
                  t,
                  Translates.You_have_unsaved_changes
                )}. ${getTranslations(
                  t,
                  Translates.Are_you_sure_you_want_to_leave
                )}?`}
              />
            )}
          </Grid>
        </Grid>
      ) : null}

      {isConfigViewMode() ? (
        <div className="view-mode">
          <VisibilityIcon />
          View Only Mode
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(Header)
