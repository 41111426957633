import fieldTypes from "src/constants/fieldTypes"

export const numberWithRequired = {
  type: fieldTypes.number,
  required: {}
}
export const numberWithNotRequired = {
  type: fieldTypes.number
}
export const stringWithRequired = {
  type: fieldTypes.string,
  required: {}
}
export const stringWithNotRequired = {
  type: fieldTypes.string
}

export const arrayWithRequired = {
  type: fieldTypes.array,
  required: {}
}
export const arrayWithNotRequired = {
  type: fieldTypes.array
}

export const utilTypes = {
  IfText: "IfText",
  AND: "AND",
  OR: "OR"
}

const functionNames = {
  CheckBarcodeType: "CheckBarcodeType",
  CheckDigit: "CheckCheckDigit",
  Braces: "Braces",
  If: "If",
  CheckDataType: "CheckDataType",
  CheckEntryMethod: "CheckEntryMethod",
  CheckLengths: "CheckLength",
  CheckValueRange: "CheckValueRange",
  CheckDecimalRange: "CheckDecimalRange",
  CheckLengthRange: "CheckLengthRange",
  RegEx: "RegEx",
  IsEqual: "IsEqual",
  IsGreaterThan: "IsGreaterThan",
  IsGreaterThanOrEqual: "IsGreaterThanOrEqual",
  IsLessThan: "IsLessThan",
  IsLessThanOrEqual: "IsLessThanOrEqual",
  IsIn: "IsIn",
  StartsWith: "StartsWith",
  EndsWith: "EndsWith",
  IsNull: "IsNull",
  IsNullOrEmpty: "IsNullOrEmpty",
  IsNullOrWhiteSpace: "IsNullOrWhiteSpace",
  IsNotNull: "IsNotNull",
  IsNotNullOrEmpty: "IsNotNullOrEmpty",
  IsNotNullOrWhiteSpace: "IsNotNullOrWhiteSpace",
  HasConsecutiveSameDigits: "HasConsecutiveSameDigits",
  AllowScan: "AllowScan",
  AllowKey: "AllowKey",
  AutoStore: "AutoStore",
  Lookup: "Lookup",
  LookupRoutine: "LookupRoutine",
  Error: "ERROR",
  Trim: "Trim",
  LTrim: "LTrim",
  RTrim: "RTrim",
  LPad: "LPad",
  RPad: "RPad",
  SubString: "SubString",
  SubStringFromEnd: "SubStringFromEnd",
  ToUpper: "ToUpper",
  ToLower: "ToLower",
  Remove: "Remove",
  Replace: "Replace",
  RemoveFromEnd: "RemoveFromEnd",
  Strip: "Strip",
  StripControlChars: "StripControlChars",
  Split: "Split",
  Insert: "Insert",
  ExpandUpceToUpca: "ExpandUpceToUpca",
  AddCheckDigit: "AddCheckDigit",
  RestoreOriginal: "RestoreOriginal",
  Else: "Else",
  Endif: "Endif",
  HasConsecutiveSameCapture: "HasConsecutiveSameCapture",
  RecordsInLoop: "RecordsInLoop"
}

export const ruleEditorFunctions = {
  booleanFn: "booleanFunction"
}

export default functionNames
