import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  TextField,
  ListItemIcon
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import moment from "moment"
import { useEffect } from "react"
import { getTranslations, sortData } from "src/utils/helper"

const ScheduleFilterComponent = ({
  t,
  Translates,
  storeData,
  multipleCustomersAssigned,
  handleChange,
  filteredRegion1Values,
  filteredRegion2Values,
  filteredRegion3Values,
  filteredRegion4Values,
  region1,
  region2,
  region3,
  region4,
  MenuProps,
  countryName,
  handleChangeCountry,
  uniqCountries,
  stateName,
  handleChangeState,
  uniqStates,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  handleKeyDown,
  statusName,
  handleChangeStatus,
  isAllSelected,
  sortedStatus,
  handleResetAllClick,
  applyCancelClick,
  applyFilterClick,
  uniqSiteIds = undefined as any,
  siteIdSelects = [] as any,
  handleChangeSiteId = _e => {},
  isSchedulePage = false,
  inValidStartDateFormat = false,
  inValidEndDateFormat = false,
  uniqConfigNames = undefined as any,
  configName,
  handleChangeConfig,
  isAllConfigSelected
}) => {
  useEffect(() => {
    if (isAllConfigSelected && configName.length !== uniqConfigNames.length) {
      handleChangeConfig({ target: { value: uniqConfigNames } })
    }
  }, [
    isAllConfigSelected,
    uniqConfigNames,
    configName.length,
    handleChangeConfig
  ])
  const getStartDateError = () => {
    if (inValidStartDateFormat) {
      return getTranslations(t, Translates.Invalid_Date_Format)
    }
    return ""
  }
  const getEndDateError = () => {
    if (inValidEndDateFormat) {
      return getTranslations(t, Translates.Invalid_Date_Format)
    }
    return ""
  }
  const handleConfigChange = event => {
    const {
      target: { value }
    } = event
    if (value.includes("All")) {
      if (configName.length === uniqConfigNames.length) {
        handleChangeConfig({
          target: {
            value: []
          }
        })
      } else {
        handleChangeConfig({
          target: {
            value: uniqConfigNames
          }
        })
      }
    } else {
      handleChangeConfig(event)
    }
  }
  return (
    <div className="filter-panel">
      <Grid container className="main-container">
        <Grid item xs={12}>
          <div className="gray-bg pa-4">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 className="mb-2">
                  {getTranslations(t, Translates.Filters)}
                </h4>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {storeData?.[0]?.regionalGroup1Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup1Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup1Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region1}
                            onChange={e => handleChange(e, 1)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion1Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region1.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup2Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup2Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup2Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region2}
                            onChange={e => handleChange(e, 2)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion2Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region2.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup3Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup3Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup3Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region3}
                            onChange={e => handleChange(e, 3)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion3Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region3.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                  {storeData?.[0]?.regionalGroup4Label &&
                  !multipleCustomersAssigned ? (
                    <Grid item xs={12} sm={3}>
                      <label className="f-600 text-uppercase mb-2">
                        {storeData[0].regionalGroup4Label}
                      </label>
                      <div className="text-box">
                        <FormControl
                          variant="filled"
                          className="custom-form-control select light"
                        >
                          <InputLabel
                            id="demo-multiple-checkbox-label"
                            variant="filled"
                          >
                            {storeData[0].regionalGroup4Label}
                          </InputLabel>
                          <Select
                            id="demo-multiple-checkbox"
                            multiple
                            value={region4}
                            onChange={e => handleChange(e, 4)}
                            disabled={multipleCustomersAssigned}
                            renderValue={selected => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {sortData(filteredRegion4Values)?.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={region4.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {uniqSiteIds ? (
                <Grid item xs={12} sm={3}>
                  <label className="f-600 text-uppercase mb-2">
                    {getTranslations(t, Translates.Site_ID)}
                  </label>
                  <div className="text-box">
                    <FormControl
                      variant="filled"
                      className="custom-form-control select light"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Site_ID)}
                      </InputLabel>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        value={siteIdSelects}
                        onChange={handleChangeSiteId}
                        renderValue={selected => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {uniqSiteIds?.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={siteIdSelects.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.Country)}
                </label>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.Country)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={countryName}
                      onChange={handleChangeCountry}
                      renderValue={selected => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {sortData(uniqCountries)?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={countryName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <label className="f-600 text-uppercase mb-2">
                  {getTranslations(t, Translates.State)}
                </label>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.State)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={stateName}
                      onChange={handleChangeState}
                      renderValue={selected => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {sortData(uniqStates)?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={stateName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              {uniqConfigNames ? (
                <Grid item xs={12} sm={3}>
                  <label className="f-600 text-uppercase mb-2">
                    {getTranslations(t, Translates.Configuration)}
                  </label>
                  <div className="text-box">
                    <FormControl
                      variant="filled"
                      className="custom-form-control select light"
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        variant="filled"
                      >
                        {getTranslations(t, Translates.Configuration)}
                      </InputLabel>
                      <Select
                        id="demo-multiple-checkbox"
                        multiple
                        value={configName}
                        onChange={handleConfigChange}
                        renderValue={selected => {
                          return isAllConfigSelected
                            ? getTranslations(t, Translates.All)
                            : uniqConfigNames
                                .filter(item => selected.includes(item))
                                .map(item => item)
                                .join(", ")
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="All">
                          <ListItemIcon>
                            <Checkbox checked={isAllConfigSelected} />
                          </ListItemIcon>
                          <ListItemText
                            primary={getTranslations(t, Translates.All)}
                          />
                        </MenuItem>
                        {uniqConfigNames?.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={configName?.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label className="f-600 text-uppercase mb-2">
                      {getTranslations(t, Translates.Scheduled_Date)}
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className="text-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={"Select Start Date"}
                          inputFormat="dd-MMM-yyyy"
                          value={startDate ? moment(startDate) : null}
                          onChange={value => {
                            onStartDateChange({
                              target: {
                                name: "startDate",
                                value: value
                              }
                            })
                          }}
                          maxDate={endDate ? new Date(endDate) : null}
                          renderInput={params => (
                            <TextField
                              data-testid="selectStartDate"
                              {...params}
                              fullWidth
                              onKeyDown={handleKeyDown}
                              placeholder="dd-MMM-yyyy"
                              label={getTranslations(t, Translates.Start_Date)}
                              variant="filled"
                              className="custom-form-control light date"
                              error={inValidStartDateFormat}
                              helperText={getStartDateError()}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div className="text-box">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          label={"Select End Date"}
                          inputFormat="dd-MMM-yyyy"
                          value={endDate ? moment(endDate) : null}
                          onChange={value => {
                            onEndDateChange({
                              target: {
                                name: "endDate",
                                value: value
                              }
                            })
                          }}
                          minDate={startDate ? new Date(startDate) : null}
                          renderInput={params => (
                            <TextField
                              data-testid="selectEndDate"
                              {...params}
                              fullWidth
                              onKeyDown={handleKeyDown}
                              placeholder="dd-MMM-yyyy"
                              label={getTranslations(t, Translates.End_Date)}
                              variant="filled"
                              className="custom-form-control light date"
                              error={inValidEndDateFormat}
                              helperText={getEndDateError()}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className="text-box">
                  <FormControl
                    variant="filled"
                    className="custom-form-control select light"
                  >
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      variant="filled"
                    >
                      {getTranslations(t, Translates.Status)}
                    </InputLabel>
                    <Select
                      id="demo-multiple-checkbox"
                      multiple
                      value={statusName}
                      onChange={handleChangeStatus}
                      renderValue={selected => {
                        return isAllSelected
                          ? getTranslations(t, Translates.All)
                          : sortedStatus
                              .filter(item => selected.includes(item.value))
                              .map(item => item.label)
                              .join(", ")
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="All">
                        <ListItemIcon>
                          <Checkbox checked={isAllSelected} />
                        </ListItemIcon>
                        <ListItemText
                          primary={getTranslations(t, Translates.All)}
                        />
                      </MenuItem>

                      {sortedStatus.map(i => (
                        <MenuItem key={i.value} value={i.value}>
                          <Checkbox
                            checked={statusName.indexOf(i.value) > -1}
                          />
                          <ListItemText primary={i.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Grid container className="inner-view__footer mt-6 mb-4">
                  <Grid item sm={4} className="text-left">
                    <Button
                      id="filterButton"
                      variant="text"
                      className="primary-btn mr-5 cancel-btn"
                      onClick={handleResetAllClick}
                    >
                      {isSchedulePage
                        ? getTranslations(t, Translates.RESET_TO_DEFAULT)
                        : getTranslations(t, Translates.Reset_All)}
                    </Button>
                  </Grid>
                  <Grid item sm={8}>
                    <Button
                      id="filterButton"
                      variant="text"
                      className="primary-btn mr-5 cancel-btn"
                      data-testid="cancelBtn"
                      onClick={applyCancelClick}
                    >
                      {getTranslations(t, Translates.Cancel)}
                    </Button>
                    <Button
                      id="filterButton"
                      variant="contained"
                      className="primary-btn"
                      onClick={applyFilterClick}
                    >
                      {getTranslations(t, Translates.Apply_Filter)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ScheduleFilterComponent
