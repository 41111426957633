import React from "react"
import Routes from "src/routes/routes"
import { withRouter } from "react-router-dom"
import ScrollToTop from "../components/ScrollToTop/ScrollToTop"
import InactivityLogout from "src/components/InactivityLogout/InactivityLogout"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "../AppInsights/AppInsights"
import { updateMoment } from "src/i18n/i18n"


const App = () => {
  //updating moment with defined locale options
  updateMoment()
  localStorage.setItem("isRefreshTokenInProgress", "false")
  return (
    <>
      <ScrollToTop />
      {/* istanbul ignore next */}
      <InactivityLogout />
      <Routes />
    </>
  )
}

const AppWithRouter = withRouter(App)
// export default AppWithRouter
export default withAITracking(reactPlugin, AppWithRouter)
