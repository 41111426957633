import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react"
import { useHistory } from "react-router-dom"
import RenderModal from "../RenderModal"
import GenerateSideBar from "../GenerateSidebarItems"
import { getAllIndexes, ifElseToReadable, ruleEditorTitle } from "../helpers"
import { functionTypes } from "../LRConstants"
import shortid from "shortid"
import { AlertColor, Grid } from "@mui/material"
import {
  getLayout,
  getIfElseLayout,
  setIfElseLayout,
  setLayout,
  setIsIfElseScreen,
  getIsIfElseScreen
} from "../layout.service"
import { PATH } from "src/constants/paths"
import { Loader } from "src/utils/reusables/reusable"
import AlertC from "src/components/Alert/Alert"
import { CONFIG_MODULES } from "src/utils/constants"
import RenderLayout from "./Component/RenderLayout"
import { COUNT_PROGRAMS_TYPE } from "../../CountPrograms/Constant"
import { setNewLookupRoutine } from "src/redux/slices/configSlice"
import { useDispatch } from "react-redux"
import { getIfOrIfElseRadioButtonJSX } from "../LRConstants/ifScreenRadioContent"
import { updateWarnOnTabChangeToStore } from "../../helper"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { getTranslations } from "src/utils/helper"
const CreateLookupEditor = forwardRef(
  ({ initialState, updateIfElse, locationVariable }: any, ref) => {
    const { t } = useTranslation<any>()
    const history = useHistory()
    const dispatch = useDispatch()
    let search = window.location.search || ""
    const [isShowEmptyElseBlock, setIsShowEmptyElseBlock] =
      useState<boolean>(false)
    const [isIfElseState, setIsIfElseState] = useState<boolean>(initialState)
    const [dragDropzoneShow, setDragDropzoneShow] = useState<boolean>(true)
    const [isIfElseStateEdit, setIsIfElseStateEdit] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [open, setOpen] = useState(false)
    const [modalFormSubmitCall, setModalFormSubmitCall] =
      useState<boolean>(false)
    const [item, setItem] = useState<any>({})

    const [pageHeading, setPageHeading] = useState<string>("")
    const [parameters, setParameters] = useState<any>([])

    const [isIfElseStartIndex, setIsIfElseStartIndex] = useState<number | null>(
      null
    )
    const sideBarRef = useRef<any>(null)

    const [alertProps, setAlertProps] = useState({
      title: "",
      severity: "success" as AlertColor,
      content: ""
    })
    const [showAlert, setShowAlert] = useState(false)

    const locationState = { ...locationVariable?.state }
    let lookup = locationState?.lookup
    let index = locationState?.index
    let type = locationState?.type
    let component = locationState?.component
    let countFieldComponent = locationState?.countFieldComponent
    let isRecordLevelAudit = locationState?.lookup?.isRecordLevelAudit
    let isConditionObject = locationState?.lookup?.isConditionObject
    let productFieldName = locationState?.lookup?.productMode

    let pathName = PATH.CONFIG_NEW_LOOKUP_ROUTINE
    if (
      [
        CONFIG_MODULES.CountPrograms.setup,
        CONFIG_MODULES.CountPrograms.validation,
        CONFIG_MODULES.CountPrograms.Lookup
      ].includes(component)
    ) {
      pathName = PATH.CREATE_COUNT_PROGRAMS
      if (index >= 0 && type === COUNT_PROGRAMS_TYPE.EDIT) {
        pathName = PATH.EDIT_COUNT_PROGRAMS.replace(":id", index)
      }
    } else if (CONFIG_MODULES.Audits === component) {
      pathName = PATH.AUDITS
    } else if (CONFIG_MODULES.AuditRule === component) {
      pathName = PATH.CREATE_AUDIT
    } else if (
      [
        CONFIG_MODULES.CountFieldInstructions.validation,
        CONFIG_MODULES.CountFieldInstructions.Lookup
      ].includes(component)
    ) {
      if (
        CONFIG_MODULES.CountFieldInstructions.Location === countFieldComponent
      ) {
        pathName = PATH.CONFIGURATION_COUNT_FIELDS_LOCATION
      } else if (
        CONFIG_MODULES.CountFieldInstructions.Product === countFieldComponent
      ) {
        pathName = PATH.CONFIGURATION_COUNT_FIELDS_PRODUCT
      }
    }
    const { title } = ruleEditorTitle(component, t)

    useEffect(() => {
      let isMounted = true
      if (isMounted) {
        setPageHeading(title)
      }
      return () => {
        isMounted = false
      }
      //eslint-disable-next-line
    }, [t])

    useEffect(() => {
      let isMounted = true
      if (isMounted) {
        let layout = getLayout()
        if (index !== -1 && layout.length <= 0) {
          setLayout(lookup?.Instructions ?? [])
        }
        if (
          [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
        ) {
          if (lookup?.Instructions && lookup?.Instructions?.length) {
            setIfElseLayout(lookup?.Instructions[0]?.array ?? [])
          }
        }
        setPageHeading(title)
        setLoading(false)
      }
      return () => {
        isMounted = false
      }
      //eslint-disable-next-line
    }, [])

    const toastMsgShow = alertMsg => {
      setAlertProps({
        ...alertMsg
      })
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    }
    useImperativeHandle(
      ref,
      () => ({
        isIfElseStateEditCheckFunction: () => {
          return isIfElseStateEdit
        },
        handleIfElseSubmit: () => {
          let layout = [...getLayout()]
          let ifElseLayout = [...getIfElseLayout()]

          if (isConditionObject && getIfElseLayout()?.length > 3) {
            toastMsgShow({
              title: "Condition Error",
              severity: "error" as AlertColor,
              content: "Condition will take only one"
            })
            return false
          }
          const BreakException = {}
          try {
            getIfElseLayout().forEach((el, j) => {
              if (
                el.error &&
                Object.keys(el.error).length &&
                el.error.content
              ) {
                toastMsgShow(el.error)
                throw BreakException
              }

              if (
                typeof el.content === "object" &&
                Object.keys(el.content).length
              ) {
                ifElseLayout[j] = {
                  ...ifElseLayout[j],
                  ...{ content: {} }
                }
              }
            })
          } catch (e) {
            if (e !== BreakException) throw e
            return
          }

          if (typeof isIfElseStartIndex === "number") {
            let convertedString = ifElseToReadable(ifElseLayout)
            let newArray: any = []

            if (isIfElseStateEdit && layout[isIfElseStartIndex]) {
              newArray = layout
              newArray[isIfElseStartIndex] = {
                ...newArray[isIfElseStartIndex],
                ...{ array: ifElseLayout, content: convertedString }
              }
              setIsIfElseStateEdit(false)
            } else {
              const id_gen = shortid.generate()

              newArray = [
                ...layout.slice(0, isIfElseStartIndex),
                {
                  function: functionTypes.If,
                  array: ifElseLayout,
                  id: id_gen,
                  content: convertedString,
                  isExpandMore: true
                },
                {
                  function: functionTypes.Else,
                  id: id_gen,
                  content: functionTypes.Else,
                  isExpandMore: true
                },
                {
                  function: functionTypes.Endif,
                  id: id_gen,
                  content: functionTypes.Endif
                },
                ...layout.slice(isIfElseStartIndex)
              ]

            }

            setLayout([...newArray])
            setPageHeading(title)
            setIfElseLayout([])
            setIsIfElseStartIndex(null)

            if (
              [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(
                component
              )
            ) {
              let lookup1 = {
                ...lookup,
                ...{ Instructions: newArray }
              }
              makeRedirection(lookup1)
            } else {
              make_if_screen_enable(false)
              sideBarRef.current.makeSideBarExpandCloseFromParent()
            }
          }
        },
        handleSubmit: () => {
          let layout = [...getLayout()]
          const BreakException = {}
          try {
            getLayout().forEach((el, j) => {
              if (
                el.error &&
                Object.keys(el.error).length &&
                el.error.content
              ) {
                toastMsgShow(el.error)
                throw BreakException
              }
            })
          } catch (e) {
            if (e !== BreakException) throw e
            return
          }
          let lookup1 = {
            ...lookup,
            ...{ Instructions: layout }
          }
          if (component === CONFIG_MODULES.LookupRoutines) {
            dispatch(setNewLookupRoutine(lookup1))
          }
          makeRedirection(lookup1)
        },
        cancelButtonClick: () => {
          if (
            getIsIfElseScreen() &&
            ![CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(
              component
            )
          ) {
            setPageHeading(title)
            make_if_screen_enable(false)
            setIfElseLayout([])
            setIsIfElseStartIndex(null)
            sideBarRef.current.makeSideBarExpandCloseFromParent()
          } else {
            setLayout(lookup.Instructions)
            makeRedirection(lookup)
          }
        }
      }),
      //eslint-disable-next-line
      [isIfElseStateEdit, isIfElseStartIndex]
    )

    const makeRedirection = loup => {
      updateWarnOnTabChangeToStore(false)
      setTimeout(() => {
        history.push(pathName + search, {
          data: JSON.stringify({ ...loup }),
          index: index,
          type: type
        })
      })
    }
    const make_if_screen_enable = (val: boolean) => {
      setIsIfElseState(val)
      updateIfElse(val)
      setIsIfElseScreen(val)
      return val
    }
    const removeConfirmation = rowToRemove => {
      let layout = isIfElseState ? [...getIfElseLayout()] : [...getLayout()]
      let item = layout[rowToRemove.path]
      if (
        item &&
        [functionTypes.If, functionTypes.Braces].includes(item.function)
      ) {
        let indexes: number[] = getAllIndexes(
          layout,
          layout[rowToRemove.path].id,
          true,
          "id"
        )
        layout = layout.filter((row, idx) => {
          return idx < indexes[0] || idx > indexes[indexes.length - 1]
        })
      } else {
        layout.splice(rowToRemove.path, 1)
      }

      isIfElseState ? setIfElseLayout(layout) : setLayout(layout)
    }

    return (
      <>
        <div className="d-flex">
          <div className="drag-left-panel">
            <h6 className="mb-2">
              {getTranslations(t, Translates.Available_Functions)}
            </h6>
            <GenerateSideBar
              isIfElseState={isIfElseState}
              setDragDropzoneShow={setDragDropzoneShow}
              component={component}
              ref={sideBarRef}
            />
          </div>
          <div className="pageContainer">
            <div className="d-flex justify-content-between rule-heading">
              <h6>{pageHeading}</h6>
              {!isIfElseState ? (
                <div className="d-flex justify-content-center">
                  {getIfOrIfElseRadioButtonJSX(
                    isShowEmptyElseBlock,
                    setIsShowEmptyElseBlock,
                    t
                  )}
                </div>
              ) : null}
            </div>

            {open ? (
              <RenderModal
                item={item}
                setOpen={setOpen}
                open={open}
                parameters={parameters}
                setParameters={setParameters}
                setModalFormSubmitCall={setModalFormSubmitCall}
                setItem={setItem}
                isIfElseState={isIfElseState}
              />
            ) : null}
            <div className="page">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <RenderLayout
                    isIfElseState={isIfElseState}
                    setItem={setItem}
                    setPageHeading={setPageHeading}
                    make_if_screen_enable={make_if_screen_enable}
                    setIsIfElseStateEdit={setIsIfElseStateEdit}
                    setIsIfElseStartIndex={setIsIfElseStartIndex}
                    removeRow={removeConfirmation}
                    setParameters={setParameters}
                    parameters={parameters}
                    setOpen={setOpen}
                    dragDropzoneShow={dragDropzoneShow}
                    open={open}
                    item={item}
                    setModalFormSubmitCall={setModalFormSubmitCall}
                    modalFormSubmitCall={modalFormSubmitCall}
                    component={component}
                    isRecordLevelAudit={isRecordLevelAudit}
                    isShowEmptyElseBlock={isShowEmptyElseBlock}
                    isConditionObject={isConditionObject}
                    productFieldName={productFieldName}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Grid container>{showAlert ? <AlertC {...alertProps} /> : null}</Grid>
      </>
    )
  }
)
export default CreateLookupEditor
