import {
  AutoStoreRadioOptions,
  checkBarcodeType,
  checkDataType,
  sourceForCombinedCountOptions
} from "./fieldOptions"
import functionNames, {
  arrayWithRequired,
  numberWithRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

const inputSetupFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.AllowScan,
    content: "Allow Scan",
    parameters: [],
    inputType: [
      {
        type: "multiDropdown",
        name: "parameter1",
        label: "Allow Scan*",
        options: checkBarcodeType,
        validation: { ...arrayWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.AllowKey,
    content: "Allow Key",
    parameters: [],
    inputType: [
      {
        type: "dropdown",
        name: "parameter1",
        label: "Allow Key",
        options: checkDataType,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: checkDataType.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Number of Decimals*",
        name: "parameter2",
        defaultValue: 0,
        validation: { ...numberWithRequired }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.AutoStore,
    content: "Auto Store",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: AutoStoreRadioOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: AutoStoreRadioOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...stringWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})

export default inputSetupFunctions
