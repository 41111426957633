import React, { forwardRef, useImperativeHandle, useState } from "react"
import SideBarItem from "./SideBarItem"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  FormControl,
  FilledInput,
  InputAdornment,
  IconButton
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { getSideBarItems } from "./helpers"
import shortid from "shortid"
import { CONFIG_MODULES } from "src/utils/constants"

const GenerateSideBar = forwardRef(
  ({ isIfElseState = false, setDragDropzoneShow, component }: any, ref) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
      (panel: string) =>
      //istanbul ignore next
      (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
      }
    const handleSearch = e => {
      setSearchTerm(e.target.value)
      setExpanded("panel0")
    }

    const sidebarTitle = title => {
      let titleName = ""
      if (title === "booleanFunctions") titleName = "Boolean"
      else if (title === "stringFunctions") titleName = "String"
      else if (title === "inputSetupFunctions") titleName = "Input Setup"
      else if (title === "lookupFunctions") titleName = "Lookup"
      else if (title.toLowerCase() === "ifelse") {
        titleName = "If"
        if (
          [CONFIG_MODULES.Audits, CONFIG_MODULES.AuditRule].includes(component)
        ) {
          titleName = "Conditions"
        }
      }
      //istanbul ignore next
      else titleName = title

      return titleName.toUpperCase()
    }

    const filterItems = items => {
      return searchTerm
        ? items.filter(val =>
            val.content.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : items
    }
    const renderSideBarItems = () => {
      const { sideBarItems } = getSideBarItems(isIfElseState, component)

      return sideBarItems.map((item, idx) => {
        return (
          <Accordion
            key={shortid.generate()}
            className="custom-accordion"
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(`panel${idx}`)}
            data-testid="sidebar-custom-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {sidebarTitle(Object.keys(item)[0])}
            </AccordionSummary>
            <AccordionDetails>
              {filterItems(Object.values(item)[0]).map((sideBarItem: any) => (
                <SideBarItem
                  key={sideBarItem.id}
                  data={sideBarItem}
                  setDragDropzoneShow={setDragDropzoneShow}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })
    }

    useImperativeHandle(
      ref,
      () => ({
        makeSideBarExpandCloseFromParent: () => {
          setSearchTerm("")
          setExpanded(false)
          return { expanded, searchTerm }
        }
      }),
      //eslint-disable-next-line
      []
    )

    return (
      <>
        <FormControl variant="filled" className="custom-form-control search">
          <FilledInput
            data-testid="tool-search"
            id="filled-adornment-password"
            placeholder={`Search`}
            autoComplete={"off"}
            onChange={e => {
              handleSearch(e)
            }}
            value={searchTerm}
            startAdornment={
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon></SearchIcon>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {renderSideBarItems()}
      </>
    )
  }
)

export default GenerateSideBar
