import booleanFunctions, {
  booleanError,
  booleanErrorMessage,
  sourceField
} from "./booleanFunctions"
import inputSetupFunctions from "./inputSetupFunctions"
import lookupFunctions from "./lookupFunctions"
import stringFunctions from "./stringFunctions"
import functionNames from "./functionNames"
import {
  AutoStoreRadioOptions,
  CheckDigitRadioOptions,
  LookupRoutineCopyOverRide,
  sourceForCombinedCountOptions,
  TrueOrFalse
} from "./fieldOptions"
import { AlertColor } from "@mui/material"

export const functionTypes = functionNames
export { utilTypes } from "./functionNames"
export const SIDEBAR_ITEM = "sidebarItem"
export const ROW = "row"
export const COLUMN = "column"
export const COMPONENT = "component"

export const initialData = {
  layout: [],
  components: {}
}
export const ifElseInitialData = {
  layout: [],
  components: {}
}

export const braces = {
  type: SIDEBAR_ITEM,
  function: functionTypes.Braces,
  content: "()",
  parameters: [],
  isExpandMore: true
}
export class DefaultIfElseSnippet {
  "Function": "If"
  "Condition": []
  "IfTrue": []
  "Else": []
  "ifId": ""
}

export const ifElse = [
  {
    type: SIDEBAR_ITEM,
    label: "IF",
    function: functionTypes.If,
    content: "If",
    parameters: [],
    isExpandMore: true
  }
]
export const sideBarItemKeys = {
  booleanFunctions: "booleanFunctions",
  inputSetupFunctions: "inputSetupFunctions",
  lookupFunctions: "lookupFunctions",
  stringFunctions: "stringFunctions",
  ifElse: "ifElse"
}

export const SIDEBAR_ITEMS = [
  { [sideBarItemKeys.ifElse]: ifElse },
  { [sideBarItemKeys.stringFunctions]: stringFunctions },
  { [sideBarItemKeys.booleanFunctions]: booleanFunctions },
  { [sideBarItemKeys.inputSetupFunctions]: inputSetupFunctions },
  { [sideBarItemKeys.lookupFunctions]: lookupFunctions }
]

export const getDefaultValues = (item, inputTypes, parameters) => {
  let a = {}
  let parametersCopy = JSON.parse(JSON.stringify(parameters))
  if (
    [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
      item.function
    )
  ) {
    if (
      Array.isArray(parametersCopy) &&
      parametersCopy.length > 1 &&
      parametersCopy.length !== 5
    ) {
      parametersCopy.unshift(CheckDigitRadioOptions[2].value)
    }
  } else if ([functionTypes.LookupRoutine].includes(item.function)) {
    if (
      inputTypes?.length !== parametersCopy?.length &&
      parametersCopy?.length !== 1
    ) {
      let newPramArray: any = []
      const overrides = LookupRoutineCopyOverRide.map(i => i.value)
      if (
        overrides.includes(parametersCopy[2]) ||
        (parametersCopy[1] === true && !parametersCopy[2])
      ) {
        newPramArray.push(parametersCopy[0], false, "", true, parametersCopy[2])
        parametersCopy = newPramArray
      }
    }
  }

  inputTypes?.forEach((i, inx) => {
    let defval: any = ""

    if (
      ![sourceField.name, booleanError.name, booleanErrorMessage.name].includes(
        i.name
      )
    ) {
      if (Array.isArray(parametersCopy) && parametersCopy.length) {
        const newInx: number = [sourceField.name].includes(inputTypes[0]?.name)
          ? inx - 1
          : inx
        if (
          parametersCopy[newInx] ||
          (item.function === functionTypes.AutoStore && [1, 2].includes(inx))
        ) {
          defval = parametersCopy[newInx]

          if (
            [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
              item.function
            )
          ) {
            if (newInx === 4 && Array.isArray(defval)) {
              defval = defval.join(",")
            }
          } else if (
            [functionTypes.CheckLengths, functionTypes.IsIn].includes(
              item.function
            )
          ) {
            if (newInx === 0) {
              defval = parametersCopy.join(",")
            }
          } else if (item.function === functionTypes.AutoStore) {
            const sourceForCombinedCountOptionsArr =
              sourceForCombinedCountOptions.map(i => i.value)
            const paramNumber: boolean =
              !sourceForCombinedCountOptionsArr.includes(parametersCopy[0])

            defval = parametersCopy[0]
            if (newInx === 0) {
              defval = paramNumber
                ? AutoStoreRadioOptions[0].value
                : AutoStoreRadioOptions[1].value
            } else if (
              (newInx === 1 && !paramNumber) ||
              (newInx === 2 && paramNumber)
            ) {
              defval = ""
            }
          } else if (item.function === functionTypes.LookupRoutine) {
            if (newInx === 2) {
              defval =
                defval && Array.isArray(defval) && defval.length
                  ? defval[0]?.["en-us"]
                  : ""
            }
          } else if (item.function === functionTypes.Error) {
            if (newInx === 0) {
              defval =
                defval && Array.isArray(defval) ? defval[0]?.["en-us"] : ""
            }
          } else if (
            [functionTypes.CheckBarcodeType, functionTypes.AllowScan].includes(
              item.function
            )
          ) {
            defval = parametersCopy
          }

          if (defval === true) {
            defval = TrueOrFalse[0].value
          } else if (defval === false) {
            defval = TrueOrFalse[1].value
          }

          a = { ...a, ...{ [i.name]: defval } }
        }
      }
    }
  })

  return a
}

export const lookupEmptyEditorError = {
  title: "Empty instruction",
  severity: "error" as AlertColor,
  content: "Please do drag and drop before continue with this!!!"
}

export const lookupFormatError = {
  title: "Format mismatched",
  severity: "error" as AlertColor,
  content: "Something wrong with the instruction!!!"
}
