import MUISelect from "src/components/MUIFormFields/MUISelect"
import MUIMultiSelect from "src/components/MUIFormFields/MUIMultiSelect"
import MUIText from "src/components/MUIFormFields/MUIText"
import MUIRadio from "./../../../components/MUIFormFields/MUIRadio"
import { useFormContext } from "react-hook-form"
import { functionTypes } from "./LRConstants"
import MUIAutoCompleteText from "src/components/MUIFormFields/MUIAutoCompleteText"
import {
  AutoStoreRadioOptions,
  checkDataType,
  CheckDigitRadioOptions,
  TrueOrFalse
} from "./LRConstants/fieldOptions"
import React from "react"
import {
  booleanError,
  booleanErrorMessage,
  sourceField
} from "./LRConstants/booleanFunctions"

const ModalComponents = ({ item, inputTypes, isIfElseState }) => {
  const { watch } = useFormContext() // retrieve all hook methods

  const handleMultiSelectField = (types: any, inx: number) => {
    return (
      <MUIMultiSelect
        key={inx}
        inputAttributes={{
          name: types.name,
          label: types.label,
          options: types.options ? types.options : []
        }}
      />
    )
  }

  const handleSelectField = (types: any, inx: number) => {
    return (
      <MUISelect
        key={inx}
        inputAttributes={{
          name: types.name,
          label: types.label,
          disabled: types.disabled,
          options: types.options ? types.options : []
        }}
      />
    )
  }

  const handleTextField = (types, inx) => {
    return (
      <MUIText
        key={inx}
        inputAttributes={{
          name: types.name,
          label: types.label,
          disabled: types.disabled,
          defaultValue: types.defaultValue
        }}
      />
    )
  }

  const handleAutoCompleteTextField = (types, inx) => {
    return (
      <MUIAutoCompleteText
        key={inx}
        inputAttributes={{
          name: types.name,
          label: types.label,
          disabled: types.disabled,
          options: types.options ? types.options : [],
          freeSolo: types.freeSolo ? true : false,
          isNotSort: types.isNotSort === false ? false : true
        }}
      />
    )
  }

  const handleRadioField = (types, inx) => {
    return (
      <MUIRadio
        key={inx}
        name={types.name}
        className={"mb-2"}
        formControl={{
          radioGroup: {
            row: true,
            formControlLabels: types.options ? types.options : []
          }
        }}
      />
    )
  }

  const generateFields = () => {
    if (!inputTypes) return []
    let fields: any[] = []
    inputTypes.map((types, inx) => {
      let display: boolean = true

      if (
        [functionTypes.CheckDigit, functionTypes.AddCheckDigit].includes(
          item.function
        )
      ) {
        let locInx = inx
        let barcodeInx = 0
        if ([sourceField.name].includes(inputTypes[0]?.name)) {
          locInx = inx > 0 ? inx - 1 : inx
          barcodeInx = 1
        }
        if (locInx !== 0) {
          display = false
          if (
            watch(inputTypes[barcodeInx].name) ===
            CheckDigitRadioOptions[2].value
          ) {
            display = true
          }
        }
      } else if ([functionTypes.AutoStore].includes(item.function)) {
        if (
          (inx === 1 &&
            (watch(inputTypes[0].name) === AutoStoreRadioOptions[1].value ||
              !watch(inputTypes[0].name))) ||
          (inx === 2 &&
            (watch(inputTypes[0].name) === AutoStoreRadioOptions[0].value ||
              !watch(inputTypes[0].name)))
        ) {
          display = false
        }
      } else if ([functionTypes.Error].includes(item.function)) {
        if (inx === 2 && watch(inputTypes[1].name) === TrueOrFalse[1].value) {
          display = false
        }
      } else if ([functionTypes.LookupRoutine].includes(item.function)) {
        if (
          (inx === 2 && watch(inputTypes[1].name) === TrueOrFalse[1].value) ||
          (inx === 4 && watch(inputTypes[3].name) === TrueOrFalse[1].value)
        ) {
          display = false
        }
      } else if ([functionTypes.AllowKey].includes(item.function)) {
        if (inx === 1 && watch(inputTypes[0].name) !== checkDataType[1].value) {
          display = false
        }
      }

      if (
        [
          sourceField.name,
          booleanError.name,
          booleanErrorMessage.name
        ].includes(inputTypes[inx].name)
      ) {
        display = true
      }

      if (display) {
        if (types.type === "dropdown") {
          let field: any = handleSelectField(types, inx)
          fields.push(
            <React.Fragment key={inputTypes[inx].name}>{field}</React.Fragment>
          )
        } else if (types.type === "multiDropdown") {
          let field: any = handleMultiSelectField(types, inx)
          fields.push(
            <React.Fragment key={inputTypes[inx].name}>{field}</React.Fragment>
          )
        } else if (types.type === "inputText") {
          let field: any = handleTextField(types, inx)
          fields.push(
            <React.Fragment key={inputTypes[inx].name}>{field}</React.Fragment>
          )
        } else if (types.type === "radio") {
          let field: any = handleRadioField(types, inx)
          fields.push(
            <React.Fragment key={inputTypes[inx].name}>{field}</React.Fragment>
          )
        } else if (types.type === "autoComplete") {
          let field: any = handleAutoCompleteTextField(types, inx)
          fields.push(
            <React.Fragment key={inputTypes[inx].name}>{field}</React.Fragment>
          )
        }
      }

      return true
    })
    return fields
  }

  return <>{generateFields()}</>
}

export default ModalComponents
